<template>
  <div class="product-component">
    <div v-for="(category, index1) in items" :key="index1">
      <div class="category-name">
        <!-- {{ category.trans.title }} -->
        {{ category.name }}
      </div>
      <div v-for="(item, index) in category.products" :key="index">
        <!-- v-if="item.category_id === category.id" -->
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="title" v-html="item.name" dir="rtl">
                <!-- {{ item.trans.name }} -->
              </v-list-item-title>
              <div class="price">{{ item.price }} ريال</div>
              <!-- <v-list-item-subtitle v-html="item.trans.description"> -->
              <v-list-item-subtitle v-html="item.description" dir="rtl">
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar
              size="80"
              style="margin-right: 0; margin-left: 15px"
            >
              <!-- <img :src="image_base_url + item.avatar" :alt="item.trans.name" /> -->
              <a href="#" class="img-holder">
                <img
                  :src="item.avatar"
                  class="bg-cover"
                  :alt="item.name"
                  @click="showOverlay(item.avatar)"
                />
              </a>
            </v-list-item-avatar>
          </v-list-item>
          <div class="d-flex d-rtl">
            <v-btn class="mr-3 btn" rounded text disabled>
              {{ item.mini_order }} {{ item.unit }}
            </v-btn>
            <div v-if="item.available_quantity > 0" class="input">
              <!-- <input type="number" v-model="item.quantity" readonly disabled /> -->
              <p
                @click.prevent
                style="
                  padding: 5px 0 5px 0;
                  border: 1px solid #555;
                  width: 100%;
                  border-radius: 7px;
                  text-align: center;
                  color: rgb(84, 84, 84);
                "
              >
                {{ item.quantity }}
              </p>
              <v-icon class="add" @click.prevent="increase(item)">
                mdi-plus
              </v-icon>
              <v-icon class="minus" @click.prevent="decrease(item)">
                mdi-minus
              </v-icon>
            </div>
            <div v-else>
              <v-text-field
                value="نفذت الكمية"
                filled
                disabled
                class="w-auto"
              ></v-text-field>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div class="footer-contact">
      <img src="../static/payment-footer.png" alt="payment" />
    </div>
    <div class="footer-buttons">
      <v-row>
        <v-col cols="6" md="6" sm="6" xs="6">
          <div class="btn" style="text-align: center">
            <router-link to="/contact-us"
              >تواصل معنا <i class="fas fa-envelope"></i
            ></router-link>
          </div>
        </v-col>
        <v-col cols="6" md="6" sm="6" xs="6">
          <div class="btn" style="text-align: center">
            <router-link to="/policy"
              >سياسات الاسترجاع <i class="fas fa-file"></i
            ></router-link>
          </div>
        </v-col>
      </v-row>
    </div>
    <a
      @click="checkCart()"
      class="add-cart"
      style="
        position: fixed !important;
        bottom: 2px !important;
        margin-bottom: 20px;
      "
    >
      استكمال الشراء <i class="fas fa-shopping-basket"></i>
    </a>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="buttons d-flex"> </v-card-title>

        <v-card-text class="text-center">
          <span> لا يوجد عناصر تم اختيارها</span>
        </v-card-text>
        <v-card-actions>
          <button class="btn btn-success green-btn" @click="dialog = false">
            موافق
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="500">
      <v-card>
        <v-card-title class="buttons d-flex"> </v-card-title>

        <v-card-text class="text-center">
          <span> عذرًا الحد الأدنى للطلب {{ minimum_order }} ريال</span>
        </v-card-text>
        <v-card-actions>
          <button class="btn btn-success green-btn" @click="dialog2 = false">
            موافق
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :z-index="zIndex" :value="overlay" :opacity="opacity">
      <v-icon @click.stop="overlay = !overlay"> mdi-close </v-icon>
      <v-img :src="image" width="500px"></v-img>
    </v-overlay>
  </div>
</template>
<script>
// import { mapMutations } from "vuex";
import { ServiceFactory } from "../services/ServiceFactory";
const productsService = ServiceFactory.get("product");
export default {
  data: () => ({
    // allProducts: [],
    // allProducts: [
    //   {
    //     id: 1,
    //     name: "محشي",
    //     description: "محشي ورق عنب",
    //     price: 10,
    //     category_id: 1,
    //     avatar: "https://tamratkaram.com/Areas/040320204163050020.png",
    //     mini_order: 3,
    //     available_quantity: 10,
    //     quantity: 0,
    //   },
    //   {
    //     id: 2,
    //     category_id: 1,
    //     name: "محشي",
    //     price: 10,
    //     description: "محشي ورق عنب",
    //     avatar: "https://tamratkaram.com/Areas/040320201945354020.png",
    //     mini_order: 3,
    //     available_quantity: 10,
    //     quantity: 0,
    //   },
    //   {
    //     id: 3,
    //     category_id: 1,
    //     name: "محشي",
    //     price: 10,
    //     description: "محشي ورق عنب",
    //     avatar: "../assets/images/product-1.png",
    //     mini_order: 3,
    //     available_quantity: 10,
    //     quantity: 0,
    //   },
    // ],
    // allCategories: [],
    // allCategories: [
    //   {
    //     id: 1,
    //     title: "قسم",
    //   },
    //   {
    //     id: 2,
    //     title: " 2قسم",
    //   },
    // ],
    items: [],
    product: {},
    dialog: false,
    dialog2: false,
    quantity: 0,
    productId: "",
    image_base_url: "",
    overlay: false,
    zIndex: 2000000,
    opacity: 0.88,
    image: "",
    scrollOffset: 0,
    minimum_order: 60,
  }),

  created() {
    this.getData();
    // console.log(this.allProducts);
    // this.fetchAllItems();
    // this.getAllCategories();
    // if (this.$route) {
    // }
  },
  computed: {
    counter() {
      return this.$store.state.quantity;
    },
  },
  watch: {
    overlay: {
      handler(value) {
        if (!value) {
          document.documentElement.scrollTop = this.scrollOffset;
        }
      },
    },
  },

  methods: {
    showOverlay(avatar) {
      this.image = avatar;
      // save current scroll offset
      // eslint-disable-next-line no-undef
      this.scrollOffset = $(window).scrollTop();
      this.overlay = true;
      var _this = this;
      // eslint-disable-next-line no-undef
      $(document).ready(function () {
        // eslint-disable-next-line no-undef
        $(".v-overlay__scrim").click(function () {
          _this.overlay = false;
        });
      });
    },
    increase(item) {
      // console.log(index + index1);
      // console.log("quantity", item.quantity + 1);
      // console.log("max_stock_per_order", item.max_stock_per_order);
      if (item.available_quantity > item.quantity) {
        if (
          item.max_stock_per_order == null ||
          item.max_stock_per_order >= parseInt(item.quantity + 1)
        ) {
          item.quantity++;
          this.$store.commit("increase", {
            product_id: item.id,
            product_name: item.name,
            price: item.price,
            quantity: item.quantity,
            Kg: item.mini_order,
            max_stock_per_order: item.max_stock_per_order,
          });
        } else {
          alert("عذراً ، لقد وصلت للحد الاقصى من هذا العنصر فى الطلب الواحد");
        }
      } else {
        alert("للأسف الكمية المتاحة لا تكفى المطلوب");
      }
      // let products = this.allProducts;
      // this.allProducts = null;
      // this.allProducts = products;
    },
    decrease(item) {
      // console.log('')
      if (item.quantity >= 1) {
        item.quantity--;
        this.$store.commit("decrease", {
          product_id: item.id,
          product_name: item.name,
          price: item.price,
          quantity: item.quantity,
          Kg: item.mini_order,
        });
        // let products = this.allProducts;
        // this.allProducts = null;
        // this.allProducts = products;
      }
    },

    async getData() {
      this.dataLoading = true;
      const data = await productsService.getAllData();
      this.items = data.products;
      this.minimum_order = data.minimum_order;
      // console.log(this.items[0].name);

      this.dataLoading = false;
    },
    // async fetchAllItems() {
    //   this.dataLoading = true;
    //   const data = await productsService.getAllProductsData();
    //   console.log(data.data.Products.rows);
    //   this.allProducts = data.data.Products.rows;
    //   this.image_base_url = data.data.image_base_url;
    //   if (this.allProducts.length > 0) {
    //     this.allProducts.map((item) => {
    //       item.quantity = 0;
    //       return item;
    //     });
    //   }
    //   this.dataLoading = false;
    // },
    // async getAllCategories() {
    //   this.dataLoading = true;
    //   const data = await productsService.getAllCategoriesData();
    //   this.allCategories = data.data.Categories.rows;
    //   this.dataLoading = true;
    // },
    checkCart() {
      if (this.$store.state.products.length > 0) {
        console.log(this.$store.state.products);
        let totalPrice = 0;
        this.$store.state.products.forEach((product) => {
          totalPrice += parseInt(product.quantity) * parseInt(product.price);
        });
        if (totalPrice >= this.minimum_order) {
          this.$router.push("/checkout");
        } else {
          this.dialog2 = true;
        }
      } else {
        this.dialog = true;
      }
    },
  },
};
</script>
<style>
.product-component {
  direction: ltr;
  padding-bottom: 60px;
}
.category-name {
  text-align: center;
  color: #614c37;
  font-size: 17px;
}
.green-btn {
  background-color: #4c9e53;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.product-component .v-card {
  margin-bottom: 20px;
}
.product-component .title {
  color: #4c9e53;
  font-size: 15px !important;
}
.product-component .price {
  color: #614c37;
  font-weight: 700;
  font-size: 15px;
  position: absolute;
  top: 20px;
  direction: rtl;
}
.product-component img {
  box-shadow: 0 5px 11px rgba(0, 0, 0, 0.18);
  border: 3px solid #fff;
  border-radius: 50%;
}

.product-component .v-sheet.v-card {
  padding-bottom: 20px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  background: #fff;
  border-radius: 20px;
}
.product-component
  .v-application--is-ltr
  .v-list-item__avatar:last-of-type:not(:only-child) {
  margin-left: 0 !important;
}
.product-component .d-rtl {
  direction: rtl;
}
.product-component .d-rtl .btn {
  background: #c7a354;
  color: #fff !important;
  width: 90px;
  padding: 5px;
  text-align: center;
  border-radius: 50px;
  margin-left: 15px;
}
.product-component .d-rtl .input {
  position: relative;
  width: 50%;
}
.v-list-item--three-line .v-list-item__subtitle {
  font-size: 13px;
  color: #767676;
  min-height: 36.8px;
}
.product-component .d-rtl .input input {
  padding: 5px 0 5px 0;
  border: 1px solid #555;
  width: 100%;
  border-radius: 7px;
  text-align: center;
}
.product-component .d-rtl .input .add {
  position: absolute;
  right: 21px;
  top: 5px;
  /* z-index: 20; */
  background-color: #fff;
}
.product-component .d-rtl .input .minus {
  position: absolute;
  left: 21px;
  top: 5px;
  /* z-index: 20; */
  background-color: #fff;
}
/*.w-auto{
   }*/
/* .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  width: 150px;
  padding: 0 25px;
  margin: auto;
  min-height: auto;
} */
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid #333;
  border-radius: 5px;
}
.img-holder {
  /* position: absolute; */
  /* right: 16px;
  top: 10px; */

  cursor: pointer;
  display: block;
  overflow: hidden;
}
.img-holder img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  border: 3px solid #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, 0.18);
}
.footer-contact {
  text-align: center;
  margin-top: 4px;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 15px;
  background: #fff;
  border-radius: 60px;
  margin: 0 0 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
}
.footer-contact img {
  border-radius: 0;
  height: 40px;
  box-shadow: none;
}
.footer-buttons {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.footer-buttons a {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  background: #fff !important;
  border-radius: 50px;
  padding: 10px;
  display: block;
  color: #3f3f3f !important;
  /* padding: 8px 64px; */
  text-decoration: unset;
}
.add-cart {
  /* position: fixed; */
  /* left: 20px; */
  /* right: 20px; */
  z-index: 99;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  max-width: 570px;
  margin: auto;
  margin-top: 20px;
  bottom: 20px;
  display: block;
  text-align: center;
  background-color: #4c9e53;
  border-radius: 37px;
  padding: 9px;
  cursor: pointer;
  width: 100%;
  border: none;
  color: #fff !important;
  text-decoration: unset;
  font-family: "frutiger lt arabic 55 roman" !important;
}
.add-cart a {
  color: #fff;
  text-decoration: unset;
}

@media only screen and (max-width: 426px) {
  .add-cart {
    left: 1%;
    right: unset;
    width: 98%;
  }
}
@media only screen and (max-width: 376px) {
  .add-cart {
    left: 1%;
    right: unset;
    width: 98%;
  }
}
@media only screen and (max-width: 321px) {
  .add-cart {
    left: 1%;
    right: unset;
    width: 98%;
  }
}
.v-list-item--three-line .v-list-item__subtitle {
  font-size: 13px !important;
  color: #767676 !important;
  min-height: 36.8px !important;
  font-family: "frutiger lt arabic 55 roman" !important;
}
</style>
