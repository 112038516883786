<template>
  <div class="login-page">
    <v-row justify="center" align="center" v-if="!dialog">
      <v-col class="text-center">
        <div class="image">
          <img src="../assets/images/logo.png" alt="main logo" />
          <!-- <vuetify-logo /> -->
        </div>
        <h2>الدخول الى طلباتى</h2>
        <form action="">
          <div class="tel-form">
            <vue-tel-input
              :error-messages="phoneErrors"
              @input="$v.auth.phone.$touch()"
              @blur="$v.auth.phone.$touch()"
              v-model="auth.phone"
              type="tel"
              v-bind="bindProps"
              required
            />
            <!-- <v-text-field label="Regular"></v-text-field> -->
            <v-btn @click="openDialog()" class="btn"> تسجيل الدخول </v-btn>
          </div>
        </form>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" v-if="dialog">
      <v-col class="text-center">
        <div class="image">
          <img src="../assets/images/logo.png" alt="main logo" />
          <!-- <vuetify-logo /> -->
        </div>
        <h2>الدخول الى طلباتى</h2>
        <form action="">
          <div class="tel-form">
            <v-alert
              dense
              text
              type="success"
              style="text-align: right"
              v-if="!wrongVerification"
            >
              <strong>تم إرسال كلمة المرور الى جوالك</strong>
            </v-alert>
            <v-alert
              dense
              text
              type="error"
              style="text-align: right"
              v-if="wrongVerification"
            >
              <strong>{{ errorMassage }}</strong>
            </v-alert>
            <v-text-field
              v-model="auth.password"
              :error-messages="passwordErrors"
              label="كلمة المرور"
              type="text"
              solo
              required
              @input="$v.auth.password.$touch()"
              @blur="$v.auth.password.$touch()"
              dir="rtl"
            ></v-text-field>
            <!-- <v-text-field label="Regular"></v-text-field> -->
            <v-btn @click="loginSubmit" class="btn"> تسجيل الدخول </v-btn>
          </div>
        </form>
      </v-col>
    </v-row>
    <!-- <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="buttons d-flex"> </v-card-title>

        <v-card-text>
          <span>هل انت متأكد من هذا الرقم؟</span>
          <v-text-field
            v-model="auth.phone"
            type="tel"
            solo
            disabled
            style="direction: ltr"
          ></v-text-field>
          <span>أدخل كود التفعيل</span>
          <v-text-field
            v-model="auth.password"
            :error-messages="passwordErrors"
            label="أدخل كود التفعيل"
            type="text"
            solo
            required
            @input="$v.auth.password.$touch()"
            @blur="$v.auth.password.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-action class="mt-3 mb-3">
          <button class="btn btn-success green-btn m-2" @click="loginSubmit">
            دخول
          </button>
          <button class="btn btn-success red-btn m-2" @click="dialog = false">
            الغاء
          </button>
        </v-card-action>
      </v-card>
    </v-dialog> -->
    <!-- <v-snackbar v-model="snackbar" color="red" left>
      {{ errorMassage }}
    </v-snackbar>
    <template v-if="loginErrorMessage">
      <v-snackbar color="red" left>
        {{ loginErrorMessage }}
      </v-snackbar>
    </template>
    <template v-if="loginErrorMessage">
      <v-alert type="error" color="red" left>
        {{ loginErrorMessage }}
        <router-link class="backToHome" to="/"
          >العودة الي الصفحة الرئيسية</router-link
        >
      </v-alert>
    </template> -->
  </div>
</template>
<script>
// import VuetifyLogo from "../components/VuetifyLogo.vue";
import { validationMixin } from "vuelidate";
// eslint-disable-next-line no-unused-vars
import { required, tel } from "vuelidate/lib/validators";
// import { required } from "vuelidate/lib/validators";
import { mapState, mapActions, mapGetters } from "vuex";
import { ServiceFactory } from "../services/ServiceFactory";
import axios from "axios";
import { API_URL } from "../config/index";
const Service = ServiceFactory.get("register");
export default {
  // components: {
  //   VuetifyLogo,
  // },
  mixins: [validationMixin],
  validations: {
    auth: {
      phone: {
        required,
        tel: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      },
      password: { required },
    },
  },
  data() {
    return {
      dialog: false,
      auth: {
        phone: "",
        password: "",
      },
      snackbar: false,
      snackbar1: false,
      errorMassage: "",
      bindProps: {
        mode: "international",
        disabledFetchingCountry: false,
        disabledFormatting: false,
        defaultCountry: "SA",
        placeholder: "أدخل رقم الهاتف",
        required: true,
        enabledCountryCode: false,
        type: "tel",
        formattedNumber: true,
        autocomplete: "on",
        autofocus: true,
        onlyCountries: ["SA", "UAE", "KW", "OM", "BH", "EG"],
        name: "telephone",
        validCharactersOnly: true,
        dropdownOptions: {
          disabledDialCode: true,
          showFlags: true,
          showDialCodeInList: true,
          showDialCodeInSelection: true,
        },
        inputOptions: {
          // showDialCode: true,
          type: "tel",
          placeholder: "رقم الجوال",
        },
      },
      wrongVerification: false,
    };
  },
  computed: {
    phoneErrors() {
      const errors = [];
      if (!this.$v.auth.phone.$dirty) return errors;
      !this.$v.auth.phone.required && errors.push("يجب ادخال رقم الهاتف");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.auth.password.$dirty) return errors;
      !this.$v.auth.password.required && errors.push("يجب ادخال كلمة مرور");
      return errors;
    },
    ...mapState({
      isLoading: (state) => state.login.isLoading,
      loginErrorMessage: (state) => state.login.loginErrorMessage,
      // loginSuccessful: (state) => state.login.loginSuccessful,
    }),
    ...mapGetters(["loginSuccessful"]),
  },
  watch: {},
  methods: {
    async openDialog() {
      if (!this.$v.auth.phone.$dirty) {
        this.errorMassage = "يرجي ادخل رقم الهاتف";
        this.wrongVerification = true;
      } else {
        const sendPhoneNumber = await Service.sendPhoneNumber(
          this.auth.phone.split(" ").join("")
        );
        if (sendPhoneNumber) {
          this.dialog = true;
        }
      }
    },
    ...mapActions(["doLogin"]),
    loginSubmit: async function () {
      this.wrongVerification = false;

      axios
        .post(`${API_URL}/auth/verify`, {
          mobile: this.auth.phone.split(" ").join(""),
          verification_code: this.auth.password,
        })
        .then((response) => {
          if (response.data.status.error === false) {
            localStorage.setItem("token", response.data.data.accessToken);
            localStorage.setItem("mobile", response.data.data.mobile);
            this.$store.commit("loginSuccessful");
            // this.$router.push("/myOrders");
            window.location = "/myOrders";
          } else {
            this.errorMassage = "خطأ فى كلمة المرور";
            this.wrongVerification = true;
          }
        });
    },
  },
};
</script>
<style>
.login-page {
  width: 100%;
}
.backToHome {
  color: #fff !important;
  text-decoration: none;
}
.green-btn {
  background-color: #4c9e53;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.red-btn {
  background-color: red;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.login-page h2 {
  margin: 0 0 25px;
  text-align: center;
  color: #614c37;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
}
.login-page .image {
  max-width: 100px;
  margin: 50px auto;
}
.login-page .image img {
  width: 100%;
}
.tel-form {
  direction: ltr !important;
  text-align: left;
  max-width: 600px;
  margin: auto;
}
.tel-form input {
  border-radius: 7px;
  font-size: 14px;
  max-width: 100%;
  padding: 15px;
  border: none;
  outline: none;
  background-color: #fff;
}
.tel-form .btn {
  text-align: center;
  margin: 50px auto;
  width: 100%;
  font-size: 17px;
  border-radius: 7px;
  padding: 30px !important;
  font-weight: 600;
  background-color: #fed24e !important;
  border: 2px solid #fed24e;
  color: #424242 !important;
}
</style>
