import Service from "./Service";
import { API_URL } from "../config";

const resource = `${API_URL}/cities`;

export default {
  getAllCities() {
    return Service.get(`${resource}`, {
      // headers: {
      //     Authorization: `Bearer ${token}`,
      // },
    }).then((response) => {
      // if (response.data.done) {
      return response.data;
      // }
    });
  },
};
