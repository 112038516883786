// import axios from "axios";
// import { API_URL } from "../../config/index";
// import router from "../../router";
const Login = {
  state: {
    isLoading: false,
    loginErrorMessage: null,
    loginSuccessful: false,
    userData: {},
  },
  mutations: {
    resetState(state) {
      state.isLoading = true;
      state.loginErrorMessage = null;
      state.loginSuccessful = false;
    },
    loginSuccessful(state) {
      console.log(state);
      state.isLoading = false;
      state.loginSuccessful = true;
      console.log(state);
    },
    // Logout
    // logout () {
    //   localStorage.removeItem('token')
    //   router.push('/login')
    // },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    doLogin({ commit, state, dispatch }, loginData) {
      console.log(commit);
      console.log(state);
      console.log(dispatch);
      commit("resetState");
      // const userData = {
      //   mobile: loginData.mobile,
      //   verification_code: loginData.password,
      // };

      // axios.post(`${API_URL}/auth/verify`, userData).then((response) => {
      //   console.log(state);
      //   if (response.data.status.error === false) {
      //     localStorage.setItem("token", response.data.data.accessToken);
      //     // dispatch("checkUserData");
      //     commit("loginSuccessful");
      //     // router.push("/myOrders");
      //   } else {
      //     state.loginErrorMessage = response.data.status.message;
      //     state.isLoading = true;
      //   }
      // });
      // .catch((error) => {
      //   state.loginErrorMessage = error.response.data.message;
      //   state.isLoading = true;
      // });
    },
  },
  getters: {
    loginSuccessful(state) {
      return state.loginSuccessful;
    },
  },
};

export default Login;
