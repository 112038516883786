<template>
  <v-row justify="center" align="center">
    <v-col>
      <div class="text-center">
        <vuetify-logo />
      </div>
      <div class="slider-section">
        <slider />
      </div>
      <div class="product-section section">
        <product />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import VuetifyLogo from "../components/VuetifyLogo.vue";
import slider from "../components/slider";
import product from "../components/product";

export default {
  components: {
    // Logo,
    VuetifyLogo,
    slider,
    product,
  },
  mounted() {
    this.$store.commit("resetProducts");
  },
};
</script>
<style>
.slider-section {
  border-radius: 20px;
}
.section {
  padding: 20px 0;
}
</style>
