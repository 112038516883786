import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import jQuery from "jquery";
window.jQuery = window.$ = jQuery;
import * as VueGoogleMaps from "vue2-google-maps";

import VueTelInput from "vue-tel-input";
require("vue-tel-input/dist/vue-tel-input.css");

Vue.use(VueTelInput);

require("./assets/style/main.css");
require("./assets/custom.css");

Vue.config.productionTip = false;

Vue.prototype.$http = axios;
Vue.prototype.$store = store;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyACgY2Kl1e1DAM80BgiwVUo8BKXzJ5tU7w",
    libraries: "places",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    if (error.response.status === 401) {
      if (localStorage.getItem("token") != undefined) {
        localStorage.clear();
      }
    }
  }
);
