import Service from "./Service";
import { API_URL } from "../config";

const resource = `${API_URL}/orders`;
const orderResource = `${API_URL}/order`;
const cancelOrder = `${API_URL}/cancelOrder`;

export default {
  getAllOrders() {
    return Service.get(`${resource}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((response) => {
      // if (response.data.done) {
      return response.data;
      // }
    });
  },
  cancelOrder(id) {
    return Service.get(`${cancelOrder}/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((response) => {
      // if (response.data.done) {
      return response.data;
      // }
    });
  },
  getOrderById(id) {
    return Service.get(`${orderResource}/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((response) => {
      // if (response.data.done) {
      return response.data;
      // }
    });
  },
};
