import Service from "./Service";
import { API_URL } from "../config";

const aboutResource = `${API_URL}/getSetting`;
const socialResource = `${API_URL}/getSetting`;
const policyResource = `${API_URL}/getSetting`;
const contactUsResource = `${API_URL}/getSetting`;

export default {
  getAboutData() {
    return Service.get(`${aboutResource}`, {}).then((response) => {
      return response.data.data;
      // console.log(response.data.data);
    });
  },
  getContactData() {
    return Service.get(`${contactUsResource}`, {}).then((response) => {
      return response.data.data;
    });
  },

  getSocialData() {
    return Service.get(`${socialResource}`, {}).then((response) => {
      return response.data.data;
    });
  },

  getPolicyData() {
    return Service.get(`${policyResource}`, {}).then((response) => {
      return response.data.data;
    });
  },
};
