<template>
  <div class="sidebar-component">
    <v-app-bar>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute right temporary>
      <v-list nav dense>
        <v-icon @click.stop="drawer = !drawer"> mdi-close </v-icon>
        <v-list-item-group v-model="group">
          <img src="../../static/smoo.png" alt="main Logo" />
          <!-- <vuetify-logo /> -->
          <v-list-item>
            <v-list-item-title>
              <router-link to="/">
                <v-icon small> mdi-home </v-icon>
                الرئيسية
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="loggedIn">
            <v-list-item-title>
              <router-link to="/myOrders">
                <v-icon small> mdi-basket </v-icon>
                طلباتي
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-title>
              <router-link to="/login">
                <v-icon small> mdi-login </v-icon>
                دخول
              </router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link to="/contact-us">
                <v-icon small> mdi-email </v-icon>
                تواصل معنا
              </router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link to="/policy">
                <v-icon small> mdi-file </v-icon>
                سياسات الاسترجاع
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <router-link to="/about">
                <v-icon small> mdi-account-group </v-icon>
                من نحن
              </router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <router-link to="/bank-accounts">
                <v-icon small> mdi-cash </v-icon>
                الحسابات البنكية
              </router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="loggedIn">
            <v-list-item-title>
              <router-link to="/logout">
                <v-icon small> mdi-logout </v-icon>
                خروج
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import VuetifyLogo from "../VuetifyLogo.vue";
export default {
  data: () => ({
    drawer: false,
    group: null,
    loggedIn: localStorage.getItem("token") != undefined,
  }),
  // components: {
  //   VuetifyLogo,
  // },

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style>
.sidebar-component {
  width: fit-content;
  z-index: 100;
}
.sidebar-component .v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  font-size: 35px !important;
  color: #614c37 !important;
  width: 21px;
  height: 24px;
}
.sidebar-component .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.sidebar-component
  .v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close),
.v-navigation-drawer--temporary:not(.v-navigation-drawer--close) {
  width: 200px;
}
.v-navigation-drawer {
  width: 200px !important;
}
.sidebar-component .v-item-group img {
  width: 90px;
  display: block;
  margin: 40px auto 40px auto;
}
.sidebar-component a {
  text-decoration: none;
  color: #5f5f5f !important;
}
.sidebar-component a:hover,
.sidebar-component a:focus {
  text-decoration: none;
  color: #5f5f5f !important;
}
.v-list-item__title {
  font-size: 15px !important;
  font-weight: bold !important;
}
.sidebar-component .v-list-item__title a {
  display: block !important;
  width: 100% !important;
}
</style>
