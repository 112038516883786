import Service from "./Service";
import { API_URL } from "../config";

const resource = `${API_URL}/auth/signup`;
const verifyResource = `${API_URL}/auth/verify`;

export default {
  signup(Data) {
    return Service.post(
      `${resource}`,
      {
        ...Data,
      },
      {
        // headers: {
        //     Authorization: `Bearer ${token}`,
        // },
      }
    ).then((response) => {
      // if (response.data.done) {
      return response.data;
      // }
    });
  },
  sendPhoneNumber(phoneNumber) {
    return Service.post(
      `${resource}`,
      {
        mobile: phoneNumber,
      },
      {}
    ).then((response) => {
      // if (response.data.done) {
      return response.data.data.success;
      // }
    });
  },
  sendVerifyCode(code) {
    return Service.post(`${verifyResource}`, {
      mobile: code.mobile,
      verification_code: code.verification_code,
    }).then((response) => {
      return response.data;
    });
  },
};
