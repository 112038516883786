import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

Vue.use(Vuex);
import login from "./modules/login";
export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    totalPrice: 0,
    quantity: 0,
    phoneNumber: "",
    discount: "",
    shipping_to: {
      full_name: "",
      city: "",
      address: "",
      notes: "",
      paymentMethod: "",
      lat: null,
      lng: null,
    },
    order_type: "",
    products: [],
  },
  getters: {
    discount(state) {
      return state.discount;
    },
    phoneNumber(state) {
      return state.phoneNumber;
    },
    shipping_to(state) {
      return state.shipping_to;
    },
    products(state) {
      return state.products;
    },
    total(state) {
      return state.products.map((item) => {
        item.total = item.quantity * item.price;
      });
    },
  },
  mutations: {
    resetProducts(state) {
      state.totalPrice = 0;
      state.quantity = 0;
      state.phoneNumber = "";
      state.discount = "";
      state.shipping_to = {
        full_name: "",
        city: "",
        address: "",
        notes: "",
        paymentMethod: "",
        lat: null,
        lng: null,
      };
      state.order_type = "";
      state.products = [];
    },
    increase(state, productData) {
      let productIndex = -1;
      state.products.map((item, index) => {
        if (productData.product_id == item.product_id) {
          productIndex = index;
        }
      });
      if (productIndex > -1) {
        state.products[productIndex] = productData;
      } else {
        state.products.push(productData);
      }
    },

    decrease(state, productData) {
      console.log("productData", productData);
      if (productData.quantity == 0) {
        // find index and remove and return
        let indexToRemove = -1;
        state.products.map((item, index) => {
          if (productData.product_id == item.product_id) {
            indexToRemove = index;
          }
        });
        state.products.splice(indexToRemove, 1);
      } else {
        let productIndex = -1;
        state.products.map((item, index) => {
          if (productData.product_id == item.product_id) {
            productIndex = index;
          }
        });
        if (productIndex > -1) {
          state.products[productIndex] = productData;
        }
      }
    },
    addPhone(state, phone) {
      state.phoneNumber = phone;
    },
    addShoppingData(state, shipping_to) {
      state.shipping_to.full_name = shipping_to.name;
      state.shipping_to.city = shipping_to.city;
      state.shipping_to.address =
        shipping_to.order_type == "inRyad"
          ? shipping_to.location.address
          : shipping_to.location;
      state.shipping_to.lat = shipping_to.location.lat;
      state.shipping_to.lng = shipping_to.location.lng;
      state.shipping_to.notes = shipping_to.notes;
      state.shipping_to.paymentMethod = shipping_to.paymentMethod;
      state.discount = shipping_to.discount;
      state.order_type = shipping_to.order_type;
      state.totalPrice = shipping_to.totalPrice;
    },
  },
  actions: {},
  modules: {
    login,
  },
});
