import Service from "./Service";
import { API_URL } from "../config";

const products = `${API_URL}/getDataProducts`;
const resource = `${API_URL}/products`;
const resourceCategory = `${API_URL}/categories`;
const sliderResource = `${API_URL}/getSliderItems`;
const discountResource = `${API_URL}/voucher/validate`;

export default {
  getAllData() {
    return Service.get(`${products}`, {}).then((response) => {
      return response.data.data;
    });
  },
  getAllProductsData() {
    return Service.get(`${resource}`, {
      // headers: {
      //     Authorization: `Bearer ${token}`,
      // },
    }).then((response) => {
      // if (response.data.done) {
      return response;
      // }
    });
  },
  getAllCategoriesData() {
    return Service.get(`${resourceCategory}`, {
      // headers: {
      //     Authorization: `Bearer ${token}`,
      // },
    }).then((response) => {
      // if (response.data.done) {
      return response;
      // }
    });
  },
  getAllSliderData() {
    return Service.get(`${sliderResource}`, {
      // headers: {
      //     Authorization: `Bearer ${token}`,
      // },
    }).then((response) => {
      // if (response.data.done) {
      return response.data.data;
      // }
    });
  },
  checkDiscountCode(token, data) {
    return Service.post(
      `${discountResource}`,
      {
        voucher_code: data,
      },
      {
        headers: {
          "x-access-token": token,
        },
      }
    ).then((response) => {
      // if (response.data.done) {
      return response.data;
      // }
    });
  },
};
