import Service from "./Service";
import { API_URL } from "../config";

const resource = `${API_URL}/checkout`;
// const onlinePayment = `${API_URL}/checkout-cart`;
// const addAddress = `${API_URL}/user/address`;

export default {
  checkOut(cartData) {
    // console.log("paymentMethoud", paymentMethoud);
    return Service.post(`${resource}`, cartData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        // if (response.data.done) {
        return response.data;
        // }
      })
      .catch((error) => {
        return error.response;
      });
  },
  // creatAddress(token, addressData) {
  //   return Service.post(
  //     `${addAddress}`,
  //     {
  //       title: addressData.address,
  //       address: addressData.address,
  //       lat: addressData.lat,
  //       long: addressData.lng,
  //       default: true,
  //     },
  //     {
  //       headers: {
  //         "x-access-token": token,
  //       },
  //     }
  //   ).then((response) => {
  //     // if (response.data.done) {
  //     return response.data;
  //     // }
  //   });
  // },
  // checkOutPayment(cartId, userId, token) {
  //   return Service.get(`${onlinePayment}?uid=${userId}&cid=${cartId}`, {
  //     headers: {
  //       "x-access-token": token,
  //     },
  //   }).then((response) => {
  //     // if (response.data.done) {
  //     return response;
  //     // }
  //   });
  // },
};
