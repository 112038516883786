<template>
  <v-app>
    <sidebar />
    <router-link
      v-if="this.$route.name == 'Home'"
      class="ma-2 d-abslute"
      outlined
      to="/myOrders"
      style="z-index: 99999"
    >
      <!--  -->
      طلباتى
    </router-link>
    <router-link
      class="ma-2 d-abslute"
      outlined
      to="/"
      style="z-index: 99999"
      v-else
    >
      <v-icon> mdi-chevron-left </v-icon>
    </router-link>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <v-navigation-drawer v-model="rightDrawer" :right="right" temporary fixed>
      <v-list>
        <v-list-item @click.native="right = !right">
          <v-list-item-action>
            <v-icon light> mdi-repeat </v-icon>
          </v-list-item-action>
          <v-list-item-title>Switch drawer (click me)</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- <v-footer>
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer> -->
  </v-app>
</template>

<script>
import sidebar from "./components/layout/sidebar";
export default {
  components: {
    sidebar,
  },
  data() {
    return {
      clipped: true,
      drawer: false,
      fixed: true,
      items: [
        {
          icon: "mdi-apps",
          title: "Welcome",
          to: "/",
        },
        {
          icon: "mdi-chart-bubble",
          title: "Inspire",
          to: "/inspire",
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Vuetify.js",
    };
  },
};
</script>
<style>
.d-abslute {
  position: absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  text-decoration: none;
  border: 1px solid #614c37;
  border-radius: 8px;
  padding: 4px 10px;
  color: #614c37 !important;
  font-weight: normal;
}
</style>
