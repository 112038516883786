<template>
  <div></div>
</template>
<script>
export default {
  created() {
    localStorage.removeItem("token");
    localStorage.removeItem("mobile");
    window.location = "/";
  },
};
</script>
